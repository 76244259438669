exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-js": () => import("./../../../src/pages/advanced.js" /* webpackChunkName: "component---src-pages-advanced-js" */),
  "component---src-pages-datasets-js": () => import("./../../../src/pages/datasets.js" /* webpackChunkName: "component---src-pages-datasets-js" */),
  "component---src-pages-dti-instructions-js": () => import("./../../../src/pages/dti-instructions.js" /* webpackChunkName: "component---src-pages-dti-instructions-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboards-js": () => import("./../../../src/pages/leaderboards.js" /* webpackChunkName: "component---src-pages-leaderboards-js" */),
  "component---src-pages-models-js": () => import("./../../../src/pages/models.js" /* webpackChunkName: "component---src-pages-models-js" */),
  "component---src-pages-preprocessing-js": () => import("./../../../src/pages/preprocessing.js" /* webpackChunkName: "component---src-pages-preprocessing-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

